import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './slices/counterSlice';
import locationReducer from './slices/locationSlice';

export const reduxStore = configureStore({
	reducer: {
		counter: counterReducer,
		location: locationReducer,
	},
});
