import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	originCord: {
		lat: 23.777176,
		lng: 90.399452,
	},
	locationCord: {
		address: '',
		coordinates: { lat: null, lng: null },
	},
};

export const locationSlice = createSlice({
	name: 'location',
	initialState,
	reducers: {
		setLocationCord: (state, action) => {
			console.log(' action.payload', action.payload);
			state.locationCord.address = action.payload?.address;
			state.locationCord.coordinates = action.payload?.latLong;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setLocationCord } = locationSlice.actions;

export default locationSlice.reducer;
